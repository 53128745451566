import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import api_config from "./api.config.js"
import { faCamera, faImages, faTicket } from "@fortawesome/free-solid-svg-icons"
import logo from "../ressources/logo_light.png"
import picturesApi from "../services/picturesApi.js"
import servicesApi from "../services/servicesApi.js"
const admin = {
  logo:logo,
  name:"linstantbydavid.com",
  entities: [
    {
      id:"picture",
      name:"Photos",
      identifier_field:"id",
      icon: <FontAwesomeIcon icon={faCamera} />,
      display_type: "pictures-list",
      dependant:["gallery"],
      getAll: {
        url:api_config.api.url+"images?buyable=true",
        pagination: true,
        display_field:[
          {id:"file",type:"image",file_get:"entity-get"},
          {id:"gallery",value:"id",display:"name",type:"entity-choice",entity:"gallery",no_value:"Pas de galerie"},
          {id:"buyable",display:"Achetable",type:"boolean",display_type:"half"},
          {id:"price",display:"Prix",type:"double",display_type:"half",dependant:"buyable"},
          {id:"large_h",display:"Hauteur double",type:"boolean",display_type:"half"},
          {id:"large_w",display:"Largeur double",type:"boolean",display_type:"half"},
          {id:"private",display:"Privée",type:"boolean"}
        ]
      },
      get: {
        url:api_config.api.url+"images/:format:/:file:",
        identifier_field:":file:",
        additionnal_fields:[
          {
            id:":format:",
            options:["src","l","m","xs"],
            default:"src"
          }
        ],
        method: picturesApi.getJson,
        method_attribute: [
          {id:"format",default:"src",options:["src","l","m","xs"]},
          {id:"file",default:"entity:file"}
        ]
      },
      new: {
        url:api_config.api.url+"picture/new",
        fields:[
          {id:"file",display:"Fichier",type:"file",mimeType:["image/jpeg","image/png"],required:true},
          {id:"private",display:"Privée",type:"boolean"},
          {id:"buyable",display:"Achetable",type:"boolean"},
          {id:"price",display:"Prix",type:"double",restrict:["positive_number"],dependant:"buyable"},
          {id:"gallery",display:"Galerie",type:"entity-choice",entity:"gallery",field:"id",display_field:"name",no_value:"Pas de galerie"},
          {id:"large_h",display:"Hauteur double",type:"boolean"},
          {id:"large_w",display:"Largeur double",type:"boolean"}
        ]
      },
      new_multiple: {
        url:api_config.api.url+"picture/new/multiple",
        fields:[
          {id:"file",display:"Fichier",type:"file",mimeType:["image/jpeg","image/png"],required:true},
          {id:"private",display:"Privée",type:"boolean"},
          {id:"buyable",display:"Achetable",type:"boolean"},
          {id:"price",display:"Prix",type:"double",restrict:["positive_number"],dependant:"buyable"},
          {id:"gallery",display:"Galerie",type:"entity-choice",entity:"gallery",field:"id",display_field:"name",no_value:"Pas de galerie"},
        ]
      },
      edit: {
        url:api_config.api.url+"picture/edit",
        identifier_field:"pic",
        fields:[
          {id:"file",type:"image",file_get:"entity-get",display_only:true},
          {id:"buyable",display:"Achetable",type:"boolean",display_type:"half"},
          {id:"price",display:"Prix",type:"double",restrict:["positive_number"],dependant:"buyable",display_type:"half"},
          {id:"gallery",display:"Galerie",type:"entity-choice",entity:"gallery",field:"id",display_field:"name",no_value:"Pas de galerie"},
          {id:"large_h",display:"Hauteur double",type:"boolean",display_type:"half"},
          {id:"large_w",display:"Largeur double",type:"boolean",display_type:"half"},
          {id:"private",display:"Privée",type:"boolean"}
        ]
      },
      delete: {
        url:api_config.api.url+"picture/delete",
        identifier_field:"pic"
      }
    },
    {
      id:"gallery",
      name:"Galeries",
      identifier_field:"id",
      icon:<FontAwesomeIcon icon={faImages} />,
      getAll: {
        url:api_config.api.url+"gallery",
        pagination: false,
        display_field: [
          {id:"name",display:"Nom",type:"title"},
          {id:"private",display:"Privée",type:"boolean"},
          {id:"cover",display:"Couverture",type:"entity-image",entity:"picture",method:"get"},
          {id:"link",display:"link",type:"custom-gallery-link"}
        ]
      },
      get:{
        url:api_config.api.url+"gallery/:id:",
        identifier_field:":id:",
        fields: [
          {id:"name",display:"Nom",type:"title"},
          {id:"private",display:"Privée",type:"boolean"},
          {id:"pictures",display:"Photos",type:"pictures-list",entity:"picture"}
        ]
      },
      display_type:"",
      new: {
        url:api_config.api.url+"gallery/new",
        fields:[
          {id:"name",display:"Nom",type:"title",required:true},
          {id:"private",display:"Privée",type:"boolean"},
          {id:"password",display:"Mot de passe",type:"password",dependant:"private"}
        ]
      },
      edit: {
        url:api_config.api.url+"gallery/edit",
        identifier_field:"gallery",
        fields:[
          {id:"cover",display:"Couverture",type:"entity-image",entity:"picture",method:"get",display_only:true},
          {id:"name",display:"Nom",type:"title",required:true},
          {id:"private",display:"Privée",type:"boolean"},
          {id:"password",display:"Mot de passe",type:"password",dependant:"private"}
        ]
      },
      delete: {
        url:api_config.api.url+"gallery/delete",
        identifier_field:"gallery"
      }
    },
    {
      id:"services",
      name:"Services",
      identifier_field:"id",
      icon: <FontAwesomeIcon icon={faTicket} />,
      display_type: "",
      getAll: {
        url:api_config.api.url+"services/all",
        pagination: true,
        display_field:[
          {id:"picture",type:"image",file_get:"entity-get"},
          {id:"background_color",display:"Couleur de fond (hexadecimal)",type:"color-string"},
          {id:"name",display:"Nom",type:"title",display_type:"half"},
          {id:"price",display:"Prix",type:"double",display_type:"half"},
          {id:"description",display:"Description",type:"text"},
          {id:"large",display:"Affichage large",type:"boolean",display_type:"half"},
          {id:"private",display:"Privé",type:"boolean",display_type:"half"}
        ]
      },
      get: {
        url:api_config.api.url+"services/images/:format:/:file:",
        identifier_field:":file:",
        additionnal_fields:[
          {
            id:":format:",
            options:["src","l","s"],
            default:"src"
          }
        ],
        method: servicesApi.getJson,
        method_attribute: [
          {id:"format",default:"s",options:["src","l","s"]},
          {id:"file",default:"entity:picture"}
        ]
      },
      new: {
        url:api_config.api.url+"services/new",
        fields:[
          {id:"picture",display:"Image",type:"file",mimeType:["image/jpeg","image/png"]},
          {id:"background_color",display:"Couleur de fond (hexadecimal)",type:"color-string"},
          {id:"name",display:"Nom",type:"title",required:true},
          {id:"price",display:"Prix",type:"double",restrict:["positive_number"],required:true},
          {id:"description",display:"Description",type:"text",required:true},
          {id:"large",display:"Affichage large",type:"boolean",display_type:"half"},
          {id:"private",display:"Privé",type:"boolean",display_type:"half"}
        ]
      },
      edit: {
        url:api_config.api.url+"services/edit",
        identifier_field:"service",
        fields:[
          {id:"picture",display:"Image",type:"image",file_get:"entity-get",mimeType:["image/jpeg","image/png"]},
          {id:"background_color",display:"Couleur de fond (hexadecimal)",type:"color-string"},
          {id:"name",display:"Nom",type:"title",required:true},
          {id:"price",display:"Prix",type:"double",restrict:["positive_number"],required:true},
          {id:"description",display:"Description",type:"text",required:true},
          {id:"large",display:"Affichage large",type:"boolean",display_type:"half"},
          {id:"private",display:"Privé",type:"boolean",display_type:"half"}
        ]
      },
      delete: {
        url:api_config.api.url+"services/delete",
        identifier_field:"service"
      }
    }
  ]
}
export default admin