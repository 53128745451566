import { useEffect } from "react"
import { useState } from "react"

const Image = (props) => {
  const [loading,setLoading] = useState(true)
  const [element,setElement] = useState(null)

  useEffect(()=>{
    let type = props.type
    let config = props.config
    let e
    let f = props.f
    let g = props.g
    if(g) {
      let ga = props.ga
      if(!ga) ga = props.gj
      if(ga) {
        g(ga).then(resp=>{
          setElement(resp)
          setLoading(false)
        }).catch(e => {
          console.log(e)
          setElement(props.no_image)
          setLoading(false)
        })
      } else {
        setElement(props.no_image)
        setLoading(false)
      }
    }
    switch (type) {
      case "image":
        e = props.e
        break
      case "entity-image":
        let c = props.e
        for (let i = 0; i < config.entities.length; i++) {
          let z = config.entities[i]
          if(z.id===c.entity) {
            e = z[c.method]
            break
          }
        }
        f = f[c.id]
        break
      default:
        e = props.e
        break
    }
    
    if(f){
      var json = {}
      for (let i = 0; i < e.method_attribute.length; i++) {
        let elem = e.method_attribute[i]
        if(/^entity:/.test(elem.default)) json[elem.id] = f[elem.default.replace("entity:","")]
        else {
          if(elem.id in props && elem.options.includes(props[elem.id])) json[elem.id] = props[elem.id]
          else json[elem.id] = elem.default
        }
      }
      e.method(json).then(resp => {
        setElement(resp)
        setLoading(false)
      }).catch(e => {
        console.log(e)
        setElement(props.no_image)
        setLoading(false)
      })
    } else {
      setElement(props.no_image)
      setLoading(false)
    }
  },[])

  return (<>
    {!loading && element  }
  </>)
}

export default Image