import { useEffect, useRef, useState } from "react"
import picturesApi from "../services/picturesApi"
import authApi from "../services/authAPI"
import { useNavigate } from "react-router-dom"
import config from "../config/admin.config"
import Image from "../components/Image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faImage, faSquarePlus, faTrashCan } from "@fortawesome/free-regular-svg-icons"
import dataApi from "../services/dataApi"
import { fri9g_notify } from "fri9g-notification-react"
import { faLink, faPhoneVolume } from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faInstagram, faLinkedin, faTiktok } from "@fortawesome/free-brands-svg-icons"


const Home = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute

  const [pictures,setPictures] = useState([])
  const picturesRef = useRef(pictures)
  const setPicturesRef = (data) => {
    picturesRef.current = data
    setPictures(data)
  }

  const [contact,setContact] = useState({email:"",phone:"",socials:[]})
  const contactRef = useRef(contact)
  const setContactRef = (data) => {
    contactRef.current = data
    setContact(data)
  }

  const sendContact = () => {
    let c = {...contactRef.current}
    if(c.email && c.phone && c.socials) {
      for (let i = 0; i < c.socials.length; i++) {
        let elt = c.socials[i]
        if(!elt.link) {
          fri9g_notify.error("Un lien est manquant dans les reseaux sociaux")
          return
        }
      }
      dataApi.setContact({data:c}).then(resp => {
        fri9g_notify.success("Contact mis a jour !")
        return
      }).catch(e => {
        console.log(e)
        fri9g_notify.error("Une erreur est survenue")
        return
      })
    } else {
      fri9g_notify.error("Un element du formulaire est manquant")
      console.log(c)
      return
    }
  }

  const [about,setAbout] = useState({picture:"",text:""})
  const aboutRef = useRef(about)
  const setAboutRef = (data) => {
    aboutRef.current = data
    setAbout(data)
  }

  const sendAbout = () => {
    dataApi.setAbout(aboutRef.current).then(resp => {
      let dd = {...aboutRef.current}
      dd.picture = ""
      setAboutRef(dd) 
      document.getElementById("inputFile").value=""
      fri9g_notify.success("Contact mis a jour !")
      return
    }).catch(e => {
      console.log(e)
      fri9g_notify.error("Une erreur est survenue")
      return
    })
  }

  useEffect(()=>{
    setRoute("Dashboard")
  },[setRoute])

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    else {
      picturesApi.getRange(0,5).then(resp => setPicturesRef(resp)).catch(e => {console.log(e);fri9g_notify.error("Une erreur est survenue lors du chargement")})
      dataApi.getContact().then(resp =>setContactRef(resp)).catch(e => {console.log(e);fri9g_notify.error("Une erreur est survenue lors du chargement")})
      dataApi.getAbout().then(resp =>setAboutRef(resp)).catch(e => {console.log(e);fri9g_notify.error("Une erreur est survenue lors du chargement")})
    }
  },[navigate])

  return (<div className="page dashboard">
    <div className="section s2y">
      <div className="section-scroll">
        <h2>5 Dernières photos postées</h2>
        {/* {pictures && pictures.map((e,k)=><div className="pic" key={k} data-id={e.id}>
          {picturesApi.get("m",e.file)}
        </div>)} */}
        {pictures && pictures.map((e,k)=><div className="pic" key={k} data-id={e.id}>
          <Image e={config.entities[0].get} f={e} type="image" format="m"/>
        </div>)}
      </div>
    </div>
    <div className="section s3x">
      <div className="section-scroll contact">
        <h2>Contact</h2>
        <div className="others">
          {contactRef.current && <div className="form-mod">
            <h2><FontAwesomeIcon icon={faEnvelope} /> Email</h2>
            <input type="text" id="email-contact" value={contactRef.current.email} onChange={(z)=>{z.preventDefault();let dd = {...contactRef.current};dd.email = z.target.value;setContactRef(dd)}}/>
          </div>}
          {contactRef.current && <div className="form-mod">
            <h2><FontAwesomeIcon icon={faPhoneVolume} /> Telephone</h2>
            <input type="text" id="phone-contact" value={contactRef.current.phone} onChange={(z)=>{z.preventDefault();let dd = {...contactRef.current};dd.phone = z.target.value;setContactRef(dd)}}/>
          </div>}
        </div>
        <div className="social">
          <div className="controls">
            <h2>Reseaux Sociaux</h2>
            <button><FontAwesomeIcon icon={faSquarePlus} onClick={(z)=>{z.preventDefault();let dd = {...contactRef.current};dd.socials.push({type:"",link:""});setContactRef(dd)}}/></button>
          </div>
          <div className="socials-list">
            <div className="title-row row">
              <h4>Type</h4>
              <h4>Lien</h4>
              <h4>Action</h4>
            </div>
            {contactRef.current && contactRef.current.socials && contactRef.current.socials.map((e,k)=><div className="row" key={k+"-social-"+e.type}>
              <div className="select">
                <select value={e.type} onChange={(z)=>{z.preventDefault();let dd = {...contactRef.current};dd.socials[k].type=z.target.value;setContactRef(dd)}}>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="linkedin">Linkedin</option>
                  <option value="tiktok">Tiktok</option>
                  <option value="">Autres</option>
                </select>
                {e.type && e.type==="facebook" && <FontAwesomeIcon icon={faFacebook} />}
                {e.type && e.type==="instagram" && <FontAwesomeIcon icon={faInstagram} />}
                {e.type && e.type==="linkedin" && <FontAwesomeIcon icon={faLinkedin} />}
                {e.type && e.type==="tiktok" && <FontAwesomeIcon icon={faTiktok} />}
                {(!e.type || e.type==="") && <FontAwesomeIcon icon={faLink} />}
              </div>
              <div className="link">
                <input type="text" value={e.link} onChange={(z)=>{z.preventDefault();let dd = {...contactRef.current};dd.socials[k].link=z.target.value;setContactRef(dd)}}/>
              </div>
              <div className="action">
                <button className="delete" onClick={(z)=>{z.preventDefault();let dd = {...contactRef.current};dd.socials.splice(k,1);setContactRef(dd)}}><FontAwesomeIcon icon={faTrashCan} /></button>
              </div>
            </div>)}
          </div>
        </div>
        <div className="action-section">
          <button onClick={(z)=>{z.preventDefault();sendContact()}}>Modifier la section "Contact"</button>
        </div>
      </div>
    </div>
    <div className="section s3x about">
      <div className="about-picture">
        <div className="picture">
          {aboutRef.current && aboutRef.current.picture && <Image g={dataApi.getAboutPicture} ga={aboutRef.current.picture} no_image={<FontAwesomeIcon icon={faImage} />} />}
        </div>
        <div className="form-mod">
          <input type="file" id="inputFile" onChange={(z)=>{z.preventDefault();let dd = {...aboutRef.current};dd.picture = z.target.files[0];setAboutRef(dd);z.target.value.replace(/^.*[\\\/]/, '')}}/>
        </div>
      </div>
      <div className="section-scroll about-form">
        <div className="form-mod">
          <textarea value={aboutRef.current.text} onChange={(z)=>{z.preventDefault();let dd = {...aboutRef.current};dd.text = z.target.value;setAboutRef(dd)}}></textarea>
        </div>
        <div className="action-section">
          <button onClick={(z)=>{z.preventDefault();sendAbout()}}>Modifier la section "A propos"</button>
        </div>
      </div>
    </div>
  </div>)
}

export default Home