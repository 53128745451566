import { useEffect, useRef, useState } from "react"
import picturesApi from "../services/picturesApi"
import authApi from "../services/authAPI"
import { Link, useNavigate } from "react-router-dom"
import config from "../config/admin.config"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { faAddressBook } from "@fortawesome/free-regular-svg-icons"


const Menu = (props) => {
  const navigate = useNavigate()
  const formatDate = (str) => moment(str).format('YYYY')
  const setMenuActive = props.setMenuActive
  const menuActive = props.menuActive
  const route = props.route

  console.log(route)

  return (<nav className="menu">
  <div className="header" onClick={(e)=>{setMenuActive(!menuActive)}}>
    <img src={config.logo} alt={"logo of "+config.name} />
  </div>
  <div className="nav">
    <Link className={route==="Dashboard" ? "nav-object active" :"nav-object"} to="/"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faHouse} /></span><h4 className="nav-title">Dashboard</h4>
    </Link>
    {config.entities.map((e,k) => <Link className={route===e.id ? "nav-object active" :"nav-object"} key={k+"-"+e.id} to={"/e/"+e.id}> 
      <span className="nav-icon">{e.icon}</span><h4 className="nav-title">{e.name}</h4>
    </Link>)}
  </div>
  <div className="footer">
    <h2>{authApi.get("username")}</h2>
    <h5 onClick={(e)=>{authApi.logout();navigate("/login")}}>Logout</h5>
  </div>
  <div className="footer-copy">
    <div className="footer-copy-right">
      <p>Copyright &copy; <Link to="https://www.fri9g.com">Fri9g</Link> - { formatDate() } </p>
    </div>
  </div>
</nav>)
}

export default Menu