import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom"
import './App.css'
import moment from "moment"
import { useState } from "react"
import Home from './pages/Home'
import { Fri9gNotifier,fri9g_notify } from 'fri9g-notification-react'
import 'fri9g-notification-react/dist/index.css'
import Login from "./pages/Login"
import Page from "./pages/Page"
import authApi from "./services/authAPI"
import Menu from "./pages/Menu"
import axios from 'axios'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = `https://admin.linstantbydavid.com`

function App() {
  const formatDate = (str) => moment(str).format('YYYY')
  const [menuActive,setMenuActive] = useState(true)
  const [route,setRoute] = useState('')

  authApi.setup()
  
  return (
    <>
      <Router>
        <div className={menuActive ? "App menuActive" : "App"}>
          {authApi.isAuthenticated() && 
            <Menu setMenuActive={setMenuActive} menuActive={menuActive} route={route}/>
          }
          <Routes>
            <Route path="/" element={<Home setRoute={setRoute}/>}/>
            <Route path="/login" element={<Login setRoute={setRoute}/>}/>
            <Route path="/e/:id" element={<Page setRoute={setRoute}/>}/>
          </Routes>
          {!authApi.isAuthenticated() && <div className="footer">
            <div className="footer-copy-right">
              <p>Copyright &copy; <Link to="https://www.fri9g.com">Fri9g</Link> - { formatDate() } </p>
            </div>
          </div>}
        </div>
      </Router>
      <Fri9gNotifier position={fri9g_notify.POSITION.BOTTOM_LEFT}/>
    </>
  )
}

export default App;
