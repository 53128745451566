const url = "https://admin.linstantbydavid.com/"
const api_url = "https://api.linstantbydavid.com/"
const interface_url = "linstantbydavid.com"
const config = {
  api:{
    url:api_url,
    login:api_url+"auth/signin"
  },
  url:url,
  interface_url
}
export default config