import Axios from 'axios'
import config from '../config/api.config'

async function getRange(page=1,limit=5,optionnal={}) {
  let cplt = "?limit="+limit+"&page="+page+(optionnal.buyable ? "&buyable=true" : "")+(optionnal.strict ? "&strict=true" : "")
  let resp = await Axios.get(config.api.url+"images"+cplt)
  return resp.data
}

async function get(format,name,password="") {
  let resp = await Axios.get(config.api+"images/"+format+"/"+name+(password!==""?"?password="+password:""),{
    responseType: 'blob' 
  })
  return <img src={URL.createObjectURL(resp.data)} alt={name} />
}

async function getJson(json) {
  let resp = await Axios.get(config.api.url+"images/"+json.format+"/"+json.file+(json.password && json.password!==""?"?password="+json.password:""),{
    responseType: 'blob' 
  })
  
  return <img src={URL.createObjectURL(resp.data)} alt={json.file} />
}

const picturesApi = {
  getRange,
  get,
  getJson
}
export default picturesApi