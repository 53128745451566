import { useEffect, useRef, useState } from "react"
import authApi from "../services/authAPI"
import { useNavigate, useParams } from "react-router-dom"
import config from "../config/admin.config"
import apiConfig from "../config/api.config"
import Axios from "axios"
import Image from "../components/Image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderPlus, faXmark } from "@fortawesome/free-solid-svg-icons"
import { faPenToSquare,faTrashCan,faCircleXmark,faImage,faEye,faSquarePlus } from "@fortawesome/free-regular-svg-icons"
import { fri9g_notify } from "fri9g-notification-react"


const Page = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute
  const params = useParams()
  const [update,setUpdate] = useState(0)

  const [datas,setDatas] = useState({})
  const dataRef = useRef(datas)
  const setDataRef = (data) => {
    dataRef.current = data
    setDatas(data)
  }

  const [tempData,setTempData] = useState({})
  const tempDataRef = useRef(tempData)
  const setTempDataRef = (data) => {
    tempDataRef.current = data
    setTempData(data)
  }

  const [entity,setEntity] = useState({})
  const entityRef = useRef(entity)
  const setEntityRef = (data) => {
    entityRef.current = data
    setEntity(data)
  }

  const [entities,setEntities] = useState([])
  const entitiesRef = useRef(entities)
  const setEntitiesRef = (data) => {
    entitiesRef.current = data
    setEntities(data)
  }

  const [entityEdit,setEntityEdit] = useState(null)
  const entityEditRef = useRef(entityEdit)
  const setEntityEditRef = (data) => {
    entityEditRef.current = data
    setEntityEdit(data)
  }

  const entityEditSend = () => {
    let c = {...entityEditRef.current}
    let conformity = {...entityRef.current.edit}
    let multipart = false
    c[conformity.identifier_field]=entityEditRef.current[entityRef.current.identifier_field]
    for (let i = 0; i < conformity.fields.length; i++) {
      let e = conformity.fields[i]
      if(!e.display_only) {
        if(e.required && !c[e.id]) {
          fri9g_notify.error("Le champs \""+e.display+"\" est requis pour la creation !")
          return
        }
        if(e.type==="entity-choice") {
          if(entityEditRef.current[e.id]) c[e.id] = entityEditRef.current[e.id][e.field]
          else c[e.id]=null
        }
        if(e.type === "file" || e.type === "image") {
          if(!c.files) c.files = {}
          c.files[e.id] = c[e.id]
          multipart=true
        }
      }
    }
    if(multipart) {
      let d = c
      c = new FormData()
      for (let i = 0; i < conformity.fields.length; i++) {
        let e = conformity.fields[i]
        if(e.type==='boolean') c.append(e.id,Boolean(d[e.id]))
        else c.append(e.id,d[e.id])
      }
      c.append(conformity.identifier_field,entityEditRef.current[entityRef.current.identifier_field])
    }
    Axios.post(conformity.url,c,{
      headers:{
        "Content-Type": multipart ? "multipart/form-data" : "application/json"
      }
    }).then(resp=>{
      fri9g_notify.success("Edition réussie")
      setEntityEditRef(null)
      loadDatas()
    }).catch(err=>{
      fri9g_notify.error("Edition échouée")
    })
  }

  const [entityNew,setEntityNew] = useState(null)
  const entityNewRef = useRef(entityNew)
  const setEntityNewRef = (data) => {
    entityNewRef.current = data
    setEntityNew(data)
  }

  const createEntityNew = () => {
    let c = {}
    for (let i = 0; i < entityRef.current.new.fields.length; i++) {
      let elem = entityRef.current.new.fields[i]
      if (elem.default) c[elem.id] = elem.default
      else {
        switch (elem.type) {
          case "boolean":
            c[elem.id] = false
            break
          case "float":
          case "double":
          case "integer":
            c[elem.id] = 0
            break
          case "string":
          case "title":
          default:
            c[elem.id] = ""
            break;
        }
      }
    }
    setEntityNewRef(c)
  }

  const [entityNewMultiple,setEntityNewMultiple] = useState(null)
  const entityNewMultipleRef = useRef(entityNewMultiple)
  const setEntityNewMultipleRef = (data) => {
    entityNewMultipleRef.current = data
    setEntityNewMultiple(data)
  }

  const createEntityNewMultiple = () => {
    let c = {}
    for (let i = 0; i < entityRef.current.new_multiple.fields.length; i++) {
      let elem = entityRef.current.new_multiple.fields[i]
      if (elem.default) c[elem.id] = elem.default
      else {
        switch (elem.type) {
          case "boolean":
            c[elem.id] = false
            break
          case "float":
          case "double":
          case "integer":
            c[elem.id] = 0
            break
          case "string":
          case "title":
          default:
            c[elem.id] = ""
            break;
        }
      }
    }
    setEntityNewMultipleRef(c)
  }

  const entityNewSend = () => {
    let c = {...entityNewRef.current}
    let conformity = {...entityRef.current.new}
    let multipart = false
    for (let i = 0; i < conformity.fields.length; i++) {
      let e = conformity.fields[i]
      if(e.required && !c[e.id]) {
        fri9g_notify.error("Le champs \""+e.display+"\" est requis pour la creation !")
        return
      }
      if(e.type === "file") {
        if(!c.files) c.files = {}
        c.files[e.id] = c[e.id]
        multipart=true
      }
    }
    if(multipart) {
      let d = c
      c = new FormData()
      for (let i = 0; i < conformity.fields.length; i++) {
        let e = conformity.fields[i]
        if(e.type==='boolean') c.append(e.id,Boolean(d[e.id]))
        else if(e.id === "gallery") c.append(e.id,d[e.id].id)
        else c.append(e.id,d[e.id])
      }
      
    } else {
      c.gallery = c.gallery?.id
    }
    Axios.post(conformity.url,c,{
      headers:{
        "Content-Type": multipart ? "multipart/form-data" : "application/json"
      }
    }).then(resp => {
      fri9g_notify.success("Creation reussie !")
      setEntityNewRef(null)
      loadDatas()
      return
    }).catch(err=>{
      console.log(err)
      fri9g_notify.error("Erreur lors de la creation")
      return
    })
  }

  const entityNewMultipleSend = () => {
    let c = {...entityNewMultipleRef.current}
    let conformity = {...entityRef.current.new_multiple}
    let multipart = false
    for (let i = 0; i < conformity.fields.length; i++) {
      let e = conformity.fields[i]
      if(e.required && !c[e.id]) {
        fri9g_notify.error("Le champs \""+e.display+"\" est requis pour la creation !")
        return
      }
      if(e.type === "file") {
        if(!c.files) c.files = {}
        c.files[e.id] = c[e.id]
        multipart=true
      }
    }
    if(multipart) {
      let d = c
      c = new FormData()
      for (let i = 0; i < conformity.fields.length; i++) {
        let e = conformity.fields[i]
        if(e.type==='boolean') c.append(e.id,Boolean(d[e.id]))
        else if(e.id === "gallery") c.append(e.id,d[e.id].id)
        else if(e.type === "file") {
          for (let ss = 0; ss < d[e.id].length; ss++) {
            const element = d[e.id][ss]
            c.append(e.id,element)
          }
        }
        else c.append(e.id,d[e.id])
      }
    } else {
      c.gallery = c.gallery?.id
    }
    Axios.post(conformity.url,c,{
      headers:{
        "Content-Type": multipart ? "multipart/form-data" : "application/json"
      }
    }).then(resp => {
      fri9g_notify.success("Creation reussie !")
      setEntityNewMultipleRef(null)
      loadDatas()
      return
    }).catch(err=>{
      console.log(err)
      fri9g_notify.error("Erreur lors de la creation")
      return
    })
  }

  const [entityDelete,setEntityDelete] = useState(null)
  const entityDeleteRef = useRef(entityDelete)
  const setEntityDeleteRef = (data) => {
    entityDeleteRef.current = data
    setEntityDelete(data)
  }

  const entityDeleteSend = () => {
    let d = {...entityDeleteRef.current}
    let c = {}
    let conformity = {...entityRef.current.delete}
    c[conformity.identifier_field]=d.id
    Axios.post(conformity.url,c).then(resp =>{
      fri9g_notify.success("Suppression reussie")
      setEntityDeleteRef(null)
      loadDatas()
      return
    }).catch(err=>{
      console.log(err)
      fri9g_notify.error("Erreur lors de la suppression")
      return
    })
  }

  const loadDatas = () => {
    setEntitiesRef([])
    for (let i = 0; i < config.entities.length; i++) {
      let e = config.entities[i]
      if(e.id===params.id) setEntityRef(e)
    }
    if(entityRef.current.id) setRoute(entityRef.current.id)
    Axios.get(entityRef.current.getAll.url).then(resp=>{
      let d = {...tempDataRef.current}
      d.entityData = entityRef.current.id
      setTempDataRef(d)
      setEntitiesRef(resp.data)
    })
    if(entityRef.current.dependant) {
      for (let i = 0; i < entityRef.current.dependant.length; i++) {
        let e = entityRef.current.dependant[i]
        for (let g = 0; g < config.entities.length; g++) {
          let f = config.entities[g]
          if(e===f.id) {
            let d = {...dataRef.current}
            Axios.get(f.getAll.url).then(resp => {
              d[f.id]=resp.data
              setDataRef(d)
            })
          }
        }
      }
    }
    setUpdate(update+1)
  }

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    else loadDatas()
  },[setRoute,params.id,entityRef.current,navigate])


  return (<div className="page">
    <div className="page-action">
      <button className={"page-action-button page-action-button-new-multiple"+(entityRef.current.new_multiple?"":" disabled")} onClick={(z)=>{z.preventDefault();createEntityNewMultiple()}}><FontAwesomeIcon icon={faFolderPlus} /></button>
      <button className={"page-action-button page-action-button-new"+(entityRef.current.new?"":" disabled")} onClick={(z)=>{z.preventDefault();createEntityNew()}}><FontAwesomeIcon icon={faSquarePlus} /></button>
    </div>
    <div className={"list "+entityRef.current.display_type}>
      {entityRef.current.id && tempDataRef.current.entityData && entityRef.current.id === tempDataRef.current.entityData && <>
        {entitiesRef.current && entitiesRef.current.map((e,k)=><div className="list-entity" key={k+"-"+e.id+(e.timestamp?"-"+e.timestamp:"")} data-id={e.id}>
          {entityRef.current && entityRef.current.getAll && entityRef.current.getAll.display_field && entityRef.current.getAll.display_field.map((f,k)=><div className={"list-entity-field list-entity-field-"+f.type+(f.display_type ? " list-entity-field-display-"+f.display_type : " list-entity-field-display-classic")+(f.dependant && !e[f.dependant] ?" list-entity-field-disabled" :"")} key={k+"-"+e.id+"-"+f.id}>
            {f.type==="title" && <h2>{e[f.id]}</h2>}
            {f.type==="entity-image" && f.entity && f.method && <Image e={f} f={e} type="entity-image" config={config} no_image={<FontAwesomeIcon icon={faImage} />}format="m"/>}
            {f.type==="image" && f.file_get==="entity-get" && entityRef.current.get.method && <Image e={entityRef.current.get} f={e} type="image" no_image={<FontAwesomeIcon icon={faImage} />} format="m"/>}
            {f.type==="boolean" && <h4><span>{f.display}</span>  <input type="checkbox" defaultChecked={e[f.id]} readOnly onClick={(g)=>{g.preventDefault();return null}} /></h4>}
            {(f.type==="integer" || f.type==="double") && <h4 className={f.dependant && !e[f.dependant] ?"list-entity-field-disabled" :""}>{Number.parseFloat(e[f.id]?e[f.id]:0).toFixed(2)}</h4>}
            {f.type==="text" && <h4>{e[f.id].length>50?e[f.id].substring(0,47)+"...":e[f.id]}</h4>}
            {f.type==="color-string" && <h4>{e[f.id]}<div className="color-preview" style={{backgroundColor:e[f.id]}}></div></h4>}
            {f.type==="entity-choice" && <h4 className={!e[f.id] ?"list-entity-field-disabled" :""}>{e[f.id]?e[f.id][f.value]+" - "+e[f.id][f.display]:f.no_value}</h4>}
            {f.type==="custom-gallery-link" && <h4><a href={"https://"+apiConfig.interface_url+"/galerie/"+e.id+"/"+e.name} target="_blank" rel="noreferrer">Link</a></h4>}
          </div>)}
          <div className="list-entity-action">
            <button className={"list-entity-action-button list-entity-action-button-edit "+(entityRef.current.edit?"":"list-entity-action-button-disabled")} onClick={(z)=>{z.preventDefault();if(entityRef.current.edit) setEntityEditRef(e)}}><FontAwesomeIcon icon={faPenToSquare} /></button>
            <button className={"list-entity-action-button list-entity-action-button-delete "+(entityRef.current.delete?"":"list-entity-action-button-disabled")} onClick={(z)=>{z.preventDefault();if(entityRef.current.delete) setEntityDeleteRef(e)}}><FontAwesomeIcon icon={faTrashCan} /></button>
          </div>
        </div>)}
      </>}
    </div>
    {entityRef.current && entityRef.current.edit && entityEditRef.current && <div className="entity-window">
      <button className="entity-window-close" onClick={(z)=>{z.preventDefault();setEntityEditRef(null)}}><FontAwesomeIcon icon={faCircleXmark}  /></button>
      <div className="entity-window-form">
        {entityRef.current && entityRef.current.edit && entityRef.current.edit.fields && entityRef.current.edit.fields.map((f,k)=><div className={"list-entity-field list-entity-field-"+f.type+(f.display_type ? " list-entity-field-display-"+f.display_type : " list-entity-field-display-classic")+(f.dependant && !entityEditRef.current[f.dependant] ?" list-entity-field-disabled" :"")} key={k+"-"+f.id}>
          {f.type==="title" && <h2><label>{f.display} :</label><input type="text" value={entityEditRef.current[f.id]} onChange={(z)=>{z.preventDefault();let ee = {...entityEditRef.current};ee[f.id]=z.target.value;setEntityEditRef(ee)}}/></h2>}
          {f.type==="entity-image" && f.entity && f.method && <Image e={f} f={entityEditRef.current} type="entity-image" config={config} no_image={<FontAwesomeIcon icon={faImage} />}format="m"/>}
          {f.type==="password" && <h4><label>{f.display} :</label><input type="password" id={f.id+"-password"} value={entityEditRef.current[f.id]} autoComplete="off" onChange={(z)=>{z.preventDefault();let ee = {...entityEditRef.current};ee[f.id]=z.target.value;setEntityEditRef(ee)}}/><button onClick={(z)=>{z.preventDefault();let elem = document.getElementById(f.id+"-password");elem.type==="password"?elem.type="text":elem.type="password"}}><FontAwesomeIcon icon={faEye} /></button></h4>}
          {f.type==="image" && f.file_get==="entity-get" && entityRef.current.get.method && <><Image e={entityRef.current.get} f={entityEditRef.current} no_image={<FontAwesomeIcon icon={faImage} />} type="image"/>{!f.display_only && <h4><label htmlFor={f.id+"-file"}>{f.display} : </label><input type="file" id={f.id+"-file"} onChange={(z)=>{z.preventDefault();let ee = {...entityEditRef.current};ee[f.id]=z.target.files[0];setEntityEditRef(ee)}} /></h4>}</>}
          {f.type==="boolean" && <h4><span>{f.display}</span>  <input type="checkbox" defaultChecked={entityEditRef.current[f.id]} onClick={(g)=>{let ee = {...entityEditRef.current};ee[f.id]=!ee[f.id];setEntityEditRef(ee);}} /></h4>}
          {(f.type==="integer" || f.type==="double") && <h4><label>{f.display} : </label><input type="number" value={Number.parseFloat(entityEditRef.current[f.id]?entityEditRef.current[f.id]:0).toFixed(2)} onChange={(z)=>{z.preventDefault();let ee = {...entityEditRef.current};ee[f.id]=z.target.value;setEntityEditRef(ee)}}/></h4>}
          {f.type==="text" && <h4><textarea onChange={(z)=>{z.preventDefault();let ee = {...entityEditRef.current};ee[f.id]=z.target.value;setEntityEditRef(ee)}} value={entityEditRef.current[f.id]}/></h4>}
          {f.type==="color-string" && <h4><label>{f.display} :</label><input type="text" value={entityEditRef.current[f.id]} onChange={(z)=>{z.preventDefault();let ee = {...entityEditRef.current};ee[f.id]=z.target.value;setEntityEditRef(ee)}}/><div className="color-preview" style={{backgroundColor:entityEditRef.current[f.id]}}></div></h4>}
          {f.type==="entity-choice" && <>
              <label>{f.display} : </label>
              <select value={entityEditRef.current[f.id]?entityEditRef.current[f.id][f.field]:null} onChange={(z)=>{z.preventDefault();let ee = {...entityEditRef.current};if(!ee[f.id])ee[f.id]={};ee[f.id][f.field]=z.target.value;setEntityEditRef(ee)}}>
                <option value={null}>{f.no_value}</option>
                {dataRef.current && dataRef.current[f.entity] && dataRef.current[f.entity].map((g,h)=><option key={h+"-"+g.id} value={g[f.field]} >{g[f.field]+' - '+g[f.display_field]}</option>)}
              </select>
            </>}
        </div>)}
        <div className="entity-window-action">
          <button className={"list-entity-action-button list-entity-action-button-edit "+(entityRef.current.edit?"":"list-entity-action-button-disabled")} onClick={(z)=>{z.preventDefault();entityEditSend()}}><FontAwesomeIcon icon={faPenToSquare} /></button>
          <button className={"list-entity-action-button list-entity-action-button-delete"} onClick={(z)=>{z.preventDefault();setEntityEditRef(null)}}><FontAwesomeIcon icon={faXmark} /></button>
        </div>
      </div>
    </div>}
    {entityRef.current && entityRef.current.new && entityNewRef.current && <div className="entity-window">
      <button className="entity-window-close" onClick={(z)=>{z.preventDefault();setEntityNewRef(null)}}><FontAwesomeIcon icon={faCircleXmark}  /></button>
      <div className="entity-window-form">
        {entityRef.current && entityRef.current.new && entityRef.current.new.fields && entityRef.current.new.fields.map((f,k)=><div className={"list-entity-field list-entity-field-"+f.type+(f.display_type ? " list-entity-field-display-"+f.display_type : " list-entity-field-display-classic")+(f.dependant && !entityNewRef.current[f.dependant] ?" list-entity-field-disabled" :"")} key={k+"-"+f.id}>
          {f.type==="file" && <h4><label htmlFor={f.id+"-file"}>{f.display} : </label><input type="file" id={f.id+"-file"} onChange={(z)=>{z.preventDefault();let ee = {...entityNewRef.current};ee[f.id]=z.target.files[0];setEntityNewRef(ee)}} /></h4>}
          {f.type==="title" && <h2><label>{f.display} :</label><input type="text" value={entityNewRef.current[f.id]} onChange={(z)=>{z.preventDefault();let ee = {...entityNewRef.current};ee[f.id]=z.target.value;setEntityNewRef(ee)}}/></h2>}
          {f.type==="entity-image" && f.entity && f.method && <Image e={f} f={entityNewRef.current} type="entity-image" config={config} no_image={<FontAwesomeIcon icon={faImage} />}format="m"/>}
          {f.type==="password" && <h4><label>{f.display} :</label><input type="password" id={f.id+"-password"} value={entityNewRef.current[f.id]} autoComplete="off" onChange={(z)=>{z.preventDefault();let ee = {...entityNewRef.current};ee[f.id]=z.target.value;setEntityNewRef(ee)}}/><button onClick={(z)=>{z.preventDefault();let elem = document.getElementById(f.id+"-password");elem.type==="password"?elem.type="text":elem.type="password"}}><FontAwesomeIcon icon={faEye} /></button></h4>}
          {f.type==="image" && f.file_get==="entity-get" && entityRef.current.get.method && <><Image e={entityRef.current.get} f={entityNewRef.current} no_image={<FontAwesomeIcon icon={faImage} />} type="image"/></>}
          {f.type==="boolean" && <h4><span>{f.display}</span>  <input type="checkbox" defaultChecked={entityNewRef.current[f.id]} onClick={(g)=>{let ee = {...entityNewRef.current};ee[f.id]=!ee[f.id];setEntityNewRef(ee);}} /></h4>}
          {(f.type==="integer" || f.type==="double") && <h4><label htmlFor={f.id+"-double"}>{f.display} : </label><input id={f.id+"-double"} type="number" value={Number.parseFloat(entityNewRef.current[f.id]?entityNewRef.current[f.id]:0).toFixed(2)} onChange={(z)=>{z.preventDefault();let ee = {...entityNewRef.current};ee[f.id]=z.target.value;setEntityNewRef(ee)}}/></h4>}
          {f.type==="text" && <h4><textarea onChange={(z)=>{z.preventDefault();let ee = {...entityNewRef.current};ee[f.id]=z.target.value;setEntityNewRef(ee)}} value={entityNewRef.current[f.id]}/></h4>}
          {f.type==="color-string" && <h4><label>{f.display} :</label><input type="text" value={entityNewRef.current[f.id]} onChange={(z)=>{z.preventDefault();let ee = {...entityNewRef.current};ee[f.id]=z.target.value;setEntityNewRef(ee)}}/><div className="color-preview" style={{backgroundColor:entityNewRef.current[f.id]}}></div></h4>}
          {f.type==="entity-choice" && <>
              <label>{f.display} : </label>
              <select value={entityNewRef.current[f.id]?entityNewRef.current[f.id][f.field]:null} onChange={(z)=>{z.preventDefault();let ee = {...entityNewRef.current};if(!ee[f.id])ee[f.id]={};ee[f.id][f.field]=z.target.value;setEntityNewRef(ee)}}>
                <option value={null}>{f.no_value}</option>
                {dataRef.current && dataRef.current[f.entity] && dataRef.current[f.entity].map((g,h)=><option key={h+"-"+g.id} value={g[f.field]} >{g[f.field]+' - '+g[f.display_field]}</option>)}
              </select>
            </>}
        </div>)}
        <div className="entity-window-action">
          <button className={"list-entity-action-button list-entity-action-button-edit "+(entityRef.current.new?"":"list-entity-action-button-disabled")} onClick={(z)=>{z.preventDefault();entityNewSend()}}><FontAwesomeIcon icon={faSquarePlus} /></button>
          <button className={"list-entity-action-button list-entity-action-button-delete"} onClick={(z)=>{z.preventDefault();setEntityNewRef(null)}}><FontAwesomeIcon icon={faXmark} /></button>
        </div>
      </div>
    </div>}
    {entityRef.current && entityRef.current.new_multiple && entityNewMultipleRef.current && <div className="entity-window">
      <button className="entity-window-close" onClick={(z)=>{z.preventDefault();setEntityNewMultipleRef(null)}}><FontAwesomeIcon icon={faCircleXmark}  /></button>
      <div className="entity-window-form">
        {entityRef.current && entityRef.current.new && entityRef.current.new.fields && entityRef.current.new.fields.map((f,k)=><div className={"list-entity-field list-entity-field-"+f.type+(f.display_type ? " list-entity-field-display-"+f.display_type : " list-entity-field-display-classic")+(f.dependant && !entityNewMultipleRef.current[f.dependant] ?" list-entity-field-disabled" :"")} key={k+"-"+f.id}>
          {f.type==="file" && <h4><label htmlFor={f.id+"-file"}>{f.display} : </label><input type="file" id={f.id+"-file"} onChange={(z)=>{z.preventDefault();let ee = {...entityNewMultipleRef.current};ee[f.id]=z.target.files;setEntityNewMultipleRef(ee)}} multiple/></h4>}
          {f.type==="title" && <h2><label>{f.display} :</label><input type="text" value={entityNewMultipleRef.current[f.id]} onChange={(z)=>{z.preventDefault();let ee = {...entityNewMultipleRef.current};ee[f.id]=z.target.value;setEntityNewMultipleRef(ee)}}/></h2>}
          {f.type==="entity-image" && f.entity && f.method && <Image e={f} f={entityNewMultipleRef.current} type="entity-image" config={config} no_image={<FontAwesomeIcon icon={faImage} />}format="m"/>}
          {f.type==="password" && <h4><label>{f.display} :</label><input type="password" id={f.id+"-password"} value={entityNewMultipleRef.current[f.id]} autoComplete="off" onChange={(z)=>{z.preventDefault();let ee = {...entityNewMultipleRef.current};ee[f.id]=z.target.value;setEntityNewMultipleRef(ee)}}/><button onClick={(z)=>{z.preventDefault();let elem = document.getElementById(f.id+"-password");elem.type==="password"?elem.type="text":elem.type="password"}}><FontAwesomeIcon icon={faEye} /></button></h4>}
          {f.type==="image" && f.file_get==="entity-get" && entityRef.current.get.method && <><Image e={entityRef.current.get} f={entityNewMultipleRef.current} no_image={<FontAwesomeIcon icon={faImage} />} type="image"/></>}
          {f.type==="boolean" && <h4><span>{f.display}</span>  <input type="checkbox" defaultChecked={entityNewMultipleRef.current[f.id]} onClick={(g)=>{let ee = {...entityNewMultipleRef.current};ee[f.id]=!ee[f.id];setEntityNewMultipleRef(ee);}} /></h4>}
          {(f.type==="integer" || f.type==="double") && <h4><label htmlFor={f.id+"-double"}>{f.display} : </label><input id={f.id+"-double"} type="number" value={Number.parseFloat(entityNewMultipleRef.current[f.id]?entityNewMultipleRef.current[f.id]:0).toFixed(2)} onChange={(z)=>{z.preventDefault();let ee = {...entityNewMultipleRef.current};ee[f.id]=z.target.value;setEntityNewMultipleRef(ee)}}/></h4>}
          {f.type==="text" && <h4><textarea onChange={(z)=>{z.preventDefault();let ee = {...entityNewMultipleRef.current};ee[f.id]=z.target.value;setEntityNewMultipleRef(ee)}} value={entityNewMultipleRef.current[f.id]}/></h4>}
          {f.type==="color-string" && <h4><label>{f.display} :</label><input type="text" value={entityNewMultipleRef.current[f.id]} onChange={(z)=>{z.preventDefault();let ee = {...entityNewMultipleRef.current};ee[f.id]=z.target.value;setEntityNewMultipleRef(ee)}}/><div className="color-preview" style={{backgroundColor:entityNewMultipleRef.current[f.id]}}></div></h4>}
          {f.type==="entity-choice" && <>
              <label>{f.display} : </label>
              <select value={entityNewMultipleRef.current[f.id]?entityNewMultipleRef.current[f.id][f.field]:null} onChange={(z)=>{z.preventDefault();let ee = {...entityNewMultipleRef.current};if(!ee[f.id])ee[f.id]={};ee[f.id][f.field]=z.target.value;setEntityNewMultipleRef(ee)}}>
                <option value={null}>{f.no_value}</option>
                {dataRef.current && dataRef.current[f.entity] && dataRef.current[f.entity].map((g,h)=><option key={h+"-"+g.id} value={g[f.field]} >{g[f.field]+' - '+g[f.display_field]}</option>)}
              </select>
            </>}
        </div>)}
        <div className="entity-window-action">
          <button className={"list-entity-action-button list-entity-action-button-edit "+(entityRef.current.new_multiple?"":"list-entity-action-button-disabled")} onClick={(z)=>{z.preventDefault();entityNewMultipleSend()}}><FontAwesomeIcon icon={faSquarePlus} /></button>
          <button className={"list-entity-action-button list-entity-action-button-delete"} onClick={(z)=>{z.preventDefault();setEntityNewMultipleRef(null)}}><FontAwesomeIcon icon={faXmark} /></button>
        </div>
      </div>
    </div>}
    {entityRef.current && entityRef.current.delete && entityDeleteRef.current && <div className="entity-window">
      <button className="entity-window-close" onClick={(z)=>{z.preventDefault();setEntityDeleteRef(null)}}><FontAwesomeIcon icon={faCircleXmark}  /></button>
      <div className="entity-window-form">
        <h3>Etes vous certain de vouloir supprimer cet element ?</h3>
      <div className="entity-window-action">
          <button className={"list-entity-action-button list-entity-action-button-delete "+(entityRef.current.delete?"":"list-entity-action-button-disabled")} onClick={(z)=>{z.preventDefault();entityDeleteSend()}}><FontAwesomeIcon icon={faTrashCan} /></button>
          <button className={"list-entity-action-button list-entity-action-button-cancel "} onClick={(z)=>{z.preventDefault();setEntityDeleteRef(null)}}><FontAwesomeIcon icon={faXmark} /></button>
        </div>
      </div>
    </div>}
  </div>)
}

export default Page