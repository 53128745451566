import Axios from 'axios'
import config from '../config/api.config'

async function getContact() {
  let resp = await Axios.get(config.api.url+"contact")
  return resp.data
}

async function setContact(data) {
  try {
    let resp = await Axios.post(config.api.url+"contact",data)
    return resp.data
  } catch (error) {
    throw error
  }
}

async function getAbout(){
  let resp = await Axios.get(config.api.url+"about")
  return resp.data
}

async function setAbout(data){
  let d = new FormData()
  d.append("picture",data.picture)
  d.append("text",data.text)
  let resp = await Axios.post(config.api.url+"about",d,{
    headers:{
      "Content-Type": "multipart/form-data"
    }
  })
  return resp.data
}

async function getAboutPicture(d){
  let resp = await Axios.get(config.api.url+"about/"+d,{
    responseType: 'blob' 
  })
  
  return <img src={URL.createObjectURL(resp.data)} alt={d} />
}


const dataApi = {
  getContact,
  setContact,
  getAbout,
  setAbout,
  getAboutPicture
}
export default dataApi