import Axios from 'axios'
import config from '../config/api.config'


async function getJson(json) {
  let resp = await Axios.get(config.api.url+"services/images/"+json.format+"/"+json.file+(json.password && json.password!==""?"?password="+json.password:""),{
    responseType: 'blob' 
  })
  
  return <img src={URL.createObjectURL(resp.data)} alt={json.file} />
}

const servicesApi = {
  getJson
}
export default servicesApi